import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "security-tools-and-building-blocks-for-your-applications"
    }}>{`Security Tools and Building Blocks for your applications`}</h1>
    <p>{`It is very important to understand that Ocelot gives you the tools to address and build the security controls your
application requires.
However, it is your teams that need to use these tools effectively to implement the controls required using the
building blocks Ocelot offers you in some or all the layers mentioned above.`}</p>
    <p>{`In other words, we advise you to move away from the adoption of bolt-on security as an afterthought.
Security must be built-in, and Ocelot helps you do exactly that efficiently and effectively by offering a complete
suite of tools you can leverage upon while building and extending your solutions.`}</p>
    <p>{`In the following, we will give a brief introduction to the tools offered by Ocelot`}</p>
    <h3 {...{
      "id": "business-operation-administration"
    }}>{`Business Operation Administration`}</h3>
    <p>{`The Business Operation Administration building block in Ocelot is a vital component that empowers your teams to finely
orchestrate and manage business operations within your microservices architecture.
These operations represent the core functionalities or transactions that your application exposes to users and services.
With this module, your teams can meticulously define, customize, and control every aspect of these operations,
including the resources involved, the roles required, the security measures, and more.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Imagine your organization operates a dynamic e-commerce platform with multiple interconnected services.
Within Ocelot's Business Operation Administration, your teams can define a critical business operation
like "Order Processing." This operation is intricately linked to a set of RESTful API endpoints responsible
for order creation, payment processing, and inventory management.`}</p>
      <p parentName="blockquote">{`For this operation:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li">{`*Resource Mapping**: Ocelot allows you to associate these endpoints and microservices explicitly with the
"Order Processing" operation. This mapping ensures that only authorized services can participate in this operation,
reducing the risk of unauthorized access or data leakage.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`*Role-Based Access Control (RBAC)**: You can establish role-based access control policies for this operation.
For instance, only users with the "Order Processing Manager" role are authorized to initiate or oversee this
operation, enhancing data integrity and security.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`*Security Level and Encryption**: Recognizing the sensitivity of financial transactions, Ocelot enables you to
specify a high-security level for the "Order Processing" operation. It automatically enforces encryption
(such as TLS/SSL) for all communications related to this operation, safeguarding payment information and ensuring
compliance with security standards.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`*Monitoring and Audit Trail**: Ocelot's Business Operation Administration also facilitates detailed monitoring
and audit trail capabilities. Every interaction with the "Order Processing" operation is logged and can be reviewed
in real-time, helping you maintain visibility and compliance with regulatory requirements.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "logon-handling"
    }}>{`Logon Handling`}</h3>
    <p>{`The Logon Handling building block in Ocelot provides a streamlined and secure solution for user management,
sign-up, and sign-in processes within your applications. It empowers your teams to effortlessly integrate Ocelot with
popular identity providers such as Azure Active Directory, Google, Facebook, GitHub, and more.
This module simplifies the implementation of user authentication and authorization, ensuring seamless and secure
access to your microservices.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Consider a scenario where your organization operates a multifaceted online platform,
allowing users to access various services and resources. With Ocelot's Logon Handling, you can create a unified
authentication and authorization system.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li">{`*User Sign-Up**: Ocelot allows users to sign up easily, providing their basic information, such as name, email, and
password. This data is securely stored, and Ocelot manages user account creation and validation.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`*Single Sign-On (SSO)**: By integrating Ocelot with identity providers like Azure Active Directory, users can enjoy
the convenience of single sign-on. They can use their existing credentials from these providers to access your
application, reducing the need for multiple usernames and passwords.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`*Token-Based Authentication**: Ocelot generates tokens that include information such as user roles, groups,
and user-specific data. These tokens are issued upon successful authentication and are accessible by the integrated
identity providers. This information can be used to enforce fine-grained access control within your microservices
based on the user's role and permissions.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`*Identity Provider Integration**: Ocelot seamlessly integrates with identity providers, leveraging their robust
authentication mechanisms and user management features. This ensures that user data is kept up-to-date and
synchronized with the identity provider's records.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Role-Based Access Control (RBAC)`}</strong>{`: Ocelot's Logon Handling extends beyond mere authentication. It allows you to
implement role-based access control, enabling you to define which users or roles have access to specific microservices
or functionalities. For example, administrators may have access to privileged areas, while standard users are
restricted to basic functionalities.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "dynamic-session-handling"
    }}>{`Dynamic Session Handling`}</h3>
    <p>{`The Dynamic Session Handling module in Ocelot is a versatile solution that takes session management to the next level.
With this module, Ocelot becomes the guardian of session information, ensuring that session-related data is injected
securely into the generated tokens. This empowers your services to make informed decisions based on session attributes,
enhancing the flexibility and security of your microservices ecosystem.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Imagine your organization operates a banking application, and compliance with Strong Customer Authentication (SCA)
regulations is essential. Specifically, you need to enforce 2-factor authentication for users conducting
transactions exceeding a certain threshold during their session. Ocelot's Dynamic Session Handling enables you to
achieve this seamlessly.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Session Information in Tokens`}</strong>{`: Ocelot ensures that session data, such as user attributes and authentication
status, is encapsulated within the tokens issued during a user's session. For example, if a user initiates a
transfer request above the specified threshold, their token includes an indicator of whether they have completed
SCA during the current session.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Real-time Session Alteration`}</strong>{`: Trusted services can interact with Ocelot to alter user sessions in real-time.
This can be achieved through synchronous REST API calls or asynchronous communication via message queues.
In the SCA scenario, when a user triggers a transfer above the threshold without prior SCA, your application can
initiate a redirect to your custom SCA flow.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Session Attribute Updates`}</strong>{`: Your trusted service responsible for SCA can instruct Ocelot to update the user's
session with the specific attribute indicating SCA completion. Once updated, the user's session now contains the
SCA attribute, ensuring that subsequent transactions adhere to the regulatory requirement.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "clients-administration"
    }}>{`Clients Administration`}</h3>
    <p>{`The Clients Administration module in Ocelot empowers you to establish and manage secure system-to-system
communication within your microservices architecture. It serves as a crucial gateway for both internal services
seeking to communicate and external systems aiming to perform specific business operations.
You have the flexibility to choose between using a Client ID and Client Secret or employing certificates for
authentication, ensuring that communication channels remain robust and protected.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example`}</p>
      <p parentName="blockquote">{`Consider a scenario where your organization's banking application needs to interact with third-party systems,
such as those belonging to customers or partners. These external systems require credentials to authenticate with
your application and engage in specific business operations.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Third-Party Authentication`}</strong>{`: Ocelot enables you to create client profiles for these external systems.
You can issue unique Client IDs and Client Secrets or manage certificates for each third-party system.
This ensures that only authorized systems can access your application's services.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Access Control for Business Operations`}</strong>{`: With Clients Administration, you can define precisely which business
operations are accessible to each client. For example, a customer's system may have permission to check account
balances but not to initiate fund transfers. You can configure these access controls, allowing you to enforce
fine-grained security policies.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Distinguishing Multiple Apps`}</strong>{`: Clients Administration is not limited to external systems. It can also be used to
differentiate between various types of applications, such as desktop and mobile applications. This distinction
allows you to tailor access rights and functionalities based on the client type.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Internal Service Access Control`}</strong>{`: Beyond external systems, you can employ clients to regulate access to specific
endpoints within your microservices ecosystem. For instance, you might restrict access to sensitive financial data
to only a subset of internal services. Ocelot's Clients Administration provides the granularity needed
for such access controls.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "users-management"
    }}>{`Users Management`}</h3>
    <p>{`The Users Management module in Ocelot offers you the flexibility to choose how you handle user and credentials
administration within your microservices ecosystem. You can seamlessly integrate Ocelot with your existing
Identity and Access Management (IAM) tools through OpenID Connect (OIDC) or soon via Security Assertion Markup
Language (SAML). Alternatively, you have the option to fully offload user and credentials administration to Ocelot.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Let's explore a scenario where your organization is developing external applications, catering to entities outside
your organization, such as customers or other non-employee users. Ocelot's Users Management module becomes
exceptionally valuable in this context.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`External Application Identity Management`}</strong>{`: Ocelot can serve as a dedicated Identity Provider (IdP) for these
external applications, enabling Single Sign-On (SSO) capabilities. With lightweight OIDC support, Ocelot becomes
the central hub for managing user identities and access across multiple external applications. Users from diverse
sources can seamlessly access these applications using a single set of credentials.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Streamlined Credential Administration`}</strong>{`: Ocelot simplifies the process of user and credential administration for
external applications. It allows you to create, manage, and authenticate users efficiently. Additionally, you can
enforce security policies, password complexity rules, and multi-factor authentication (MFA) to enhance
user account security.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Interoperability with Existing IAM Tools`}</strong>{`: For internal applications and services, Ocelot's Users Management
module can seamlessly integrate with your existing IAM toolset. Whether you use OIDC or SAML, Ocelot ensures
compatibility and interoperability with your established identity management infrastructure,
allowing for consistent user experiences across your organization.`}</p>
        </li>
      </ul>
      <p parentName="blockquote">{`Enhanced Security for External Users: By offloading user and credential administration to Ocelot, you centralize
identity and access control for external users. This not only simplifies the user experience but also enhances
security. Ocelot can enforce strong authentication methods, identity verification, and access policies to protect
sensitive data and functionalities.`}</p>
    </blockquote>
    <h3 {...{
      "id": "operators-management"
    }}>{`Operators Management`}</h3>
    <p>{`The Operators Management module in Ocelot is a pivotal component that enables the administration of individuals or
automated entities known as Operators. These Operators serve as the guardians of Ocelot's security and operational
controls, overseeing various aspects of the system. Whether they are human or automated Security Officers, auditors,
or customer support agents, Operators play critical roles in maintaining system integrity, responding to security
incidents, and performing specific operational tasks.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Let's delve into practical scenarios where Operators Management becomes instrumental within your microservices ecosystem:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Auditors for System State Monitoring`}</strong>{`: Auditors, tasked with ensuring the compliance and integrity of your
microservices environment, utilize Operator credentials to access Ocelot. They can monitor the current state of
the system, reviewing access logs, security configurations, and compliance reports. Operators with auditing roles
help maintain transparency and accountability, ensuring that your system adheres to regulatory and organizational
standards.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Security Officers Enforcing Security Controls`}</strong>{`: Security Officers possess the authority to perform critical
security-related operations within Ocelot. For instance, they can execute actions such as terminating active user
sessions or blocking users in response to security threats. With Operator credentials, they hold the keys to swiftly
respond to incidents, enhancing your microservices security posture.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Customer Support Agents Performing Operational Tasks`}</strong>{`: In scenarios where customer support agents require
specialized capabilities, Operator credentials grant them access to specific operational functions. For instance,
they can assist users with account recovery, password resets, or troubleshooting access issues. This empowers
customer support teams to deliver efficient and responsive user assistance.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Automated Machines as Operators`}</strong>{`: Beyond human operators, automated systems can also act as Operators,
executing predefined tasks or responses. These machine-based Operators can trigger actions like scaling microservices
based on demand, deploying security updates, or responding to predefined security events. Operator credentials ensure
that automated tasks are executed securely and reliably.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "authorization-as-code"
    }}>{`Authorization-as-Code`}</h3>
    <p>{`Authorization-as-Code is a revolutionary approach facilitated by Ocelot, where the team responsible for a specific
microservice's codebase also possesses the ability to define and implement the service's security policies directly
within the code. This module empowers the service's development team to express security requirements as code,
ensuring that security controls remain synchronized with the evolving business logic. These code-based security
configurations are committed alongside the application code and are automatically compiled and deployed by Ocelot,
transforming them into executable security policies.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Imagine a healthcare application with a microservice responsible for managing patient records.
The development team that owns this microservice also understands the intricate details of healthcare
regulations and privacy requirements, making them best suited to define security policies.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul"><strong parentName="li">{`Expressing Security Policies as Code`}</strong>{`: Using Ocelot's Authorization-as-Code, the development team can write
security policies as code within the microservice's codebase. For instance, they might specify that only authorized
medical staff with specific roles can access patient records, and that access to sensitive patient data must be
logged and audited.`}</li>
      </ul>
    </blockquote>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Continuous Integration and Deployment (CI/CD)`}</strong>{`: The security policies, written as code, are seamlessly integrated
into the CI/CD pipeline. When the team commits changes to the microservice's code, including updates to security
policies, the CI/CD process automatically compiles and deploys these policies alongside the application code.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Real-time Synchronization`}</strong>{`: As the business logic evolves or regulatory requirements change, the development team
can update the security policies within the codebase. These changes are version-controlled, providing a historical
record of security policy alterations. The CI/CD pipeline ensures that the latest security policies are always in
effect, keeping pace with the evolving application.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Audit and Traceability`}</strong>{`: Ocelot maintains a comprehensive audit trail, linking security policy changes back to
specific commits in the source code repository. This traceability ensures accountability and allows for easy
identification of who made what security policy alterations and when.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "role-based-access-control-rbac"
    }}>{`Role-Based Access Control (RBAC)`}</h3>
    <p>{`Role-Based Access Control (RBAC) is a fundamental building block in Ocelot that enables organizations to manage
access to their microservices based on predefined roles. With RBAC, you can categorize users, services, or
operators into specific roles, each with its own set of permissions and privileges. RBAC simplifies access management,
ensures security, and enhances administrative control over your microservices ecosystem.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Consider a scenario where your organization operates a cloud-based storage service with multiple user types and
access requirements:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`User Roles`}</strong>{`: Ocelot allows you to define distinct user roles, such as "Admin," "Editor," and "Viewer."
Each role is associated with a specific set of permissions. For example, "Admin" users have full control over
file management, while "Editor" users can modify files but not manage users or change system settings, and "Viewer"
users can only view files.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Service Roles`}</strong>{`: In addition to user roles, RBAC extends to services. You can categorize your microservices
into roles based on their functionalities. For instance, a "Billing Service" may have a "Billing Administrator"
role with permissions to manage billing-related operations.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Operator Roles`}</strong>{`: Ocelot allows operators with various responsibilities to be categorized into roles as well.
For example, "Auditor" operators may have access to view system logs and audit trails, while "Support" operators
can perform customer support actions.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Fine-Grained Permissions`}</strong>{`: Within each role, you can define fine-grained permissions, specifying exactly what
actions and resources are accessible. For instance, "Admin" users may have permission to create, delete, or modify
any file, while "Editor" users can only edit files within specific folders.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Role Assignment`}</strong>{`: RBAC also enables you to assign users, services, or operators to specific roles based on
their responsibilities and access requirements. This assignment can be dynamic, adapting to changing user roles or
system requirements.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Access Control Policies`}</strong>{`: Ocelot's RBAC module ensures that access control policies are consistently enforced
across your microservices architecture. Users and services are granted access to functionalities and resources
based on their assigned roles and permissions.`}</p>
        </li>
      </ul>
    </blockquote>
    <h3 {...{
      "id": "attribute-based-access-control-abac"
    }}>{`Attribute-Based Access Control (ABAC)`}</h3>
    <p>{`Attribute-Based Access Control (ABAC) is a powerful building block in Ocelot that offers organizations a
flexible and dynamic approach to access control. ABAC enables fine-grained access decisions by considering various
attributes, such as user attributes, resource attributes, and contextual attributes, to determine whether a user or
service should be granted access to specific functionalities or resources within your microservices architecture.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Your organization operates a cloud-based file storage service that serves both internal teams and external partners.
The service hosts sensitive data, and access control must be granular and adaptive.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`User Attributes`}</strong>{`: ABAC allows you to define user attributes, such as user roles, department, and location.
For example, you can categorize users as "Admins," "Editors," or "Viewers" based on their roles and assign attributes
like "Department: Finance" or "Location: New York."`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Resource Attributes`}</strong>{`: Resource attributes describe the characteristics of the resources within your service.
In the file storage service, resources might include folders, files, or file types. You can assign attributes like
"Confidentiality: High" to certain files or "Access Control: Restricted" to specific folders.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Contextual Attributes`}</strong>{`: Contextual attributes consider the dynamic context of access requests. For instance,
ABAC can take into account factors like the time of day, the user's location, or the device being used. This
contextual information helps determine whether a specific access request is legitimate.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Access Policies`}</strong>{`: Ocelot's ABAC module empowers you to define access policies that specify under what conditions
access should be granted. For example, you can create a policy that allows "Editors" with the "Department: Finance"
attribute to access "Confidential" files during business hours while on-site.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Dynamic Access Control`}</strong>{`: One of ABAC's strengths is its adaptability. Access control decisions are made
dynamically based on attribute values and contextual information. If a user's role changes or they request access
from a different location, ABAC recalculates access decisions accordingly.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Scalability and Complexity Handling`}</strong>{`: ABAC excels in handling complex access control scenarios, especially in
large and evolving microservices architectures. Whether it's managing access to sensitive documents, restricting
access to certain resources, or enforcing compliance with regulatory requirements, ABAC provides the flexibility
and precision needed.`}</p>
        </li>
      </ul>
    </blockquote>
    <h2 {...{
      "id": "ocelots-end-user-permission-tokens-a-deep-dive"
    }}>{`Ocelot's End-User Permission Tokens: A Deep Dive`}</h2>
    <p>{`The end-user permission token is based on the
`}<a parentName="p" {...{
        "href": "https://cloud.google.com/security/infrastructure/design"
      }}>{`same idea and technology Google uses`}</a>{` to protect its own
datacenters and applications.`}</p>
    <p>{`Instead of using long-lived tokens that represent a full user and/or a full session, as usual applications do today,
Ocelot releases very short-lived tokens (a couple of minutes) containing exclusively the authorization needed by the
specific business operation the customer required, and nothing more.`}</p>
    <p>{`This not only solves the so-called "confused deputy problem", but it also makes life very complicated for the attacker,
as even if it has a foothold in your network and it can break the secure channel (TLS).
Indeed by using this method, the attacker will not be able to perform any mass attacks (as no services have the power to do
anything without an end-user permission token).`}</p>
    <blockquote>
      <p parentName="blockquote">{`The confused deputy problem:`}</p>
      <p parentName="blockquote">{`Most organizations are used to exclusively securing the infrastructure layer, without caring about the application.
This is extremely dangerous and among other issues, one of the most important is known in the literature as the
"confused deputy" problem.`}</p>
      <p parentName="blockquote"><img alt="Confused deputy problem" src={require("../drawings/confused-deputy.svg")} /></p>
      <p parentName="blockquote">{`Let’s imagine that the Backend-for-Frontend (BFF) Service can connect to the Transfer Service to perform a bank
transfer, but it cannot connect to the Market Analysis Service since it does not need to have this interaction.`}</p>
      <p parentName="blockquote">{`Better than nothing, but surely not good enough.`}</p>
      <p parentName="blockquote">{`In case the BFF has some 0-day or any misconfiguration, it can be taken over and the attacker will have full access
to the Transfer Service, as the latter will be unable to discern malicious traffic from the legit one (the attacker
acts on behalf of the BFF and can transfer all customers' money to his account for instance).`}</p>
      <p parentName="blockquote">{`To avoid this situation Ocelot does not stop at securing infrastructure.
Ocelot solves the authorization issue at the application layer, allowing very granular control of the
service mesh driven by business operations.`}</p>
    </blockquote>
    <h2 {...{
      "id": "functionality-in-beta"
    }}>{`Functionality in Beta`}</h2>
    <p>{`During the Beta stage of a component, select customers have the opportunity to enroll and participate in
testing and evaluation of a specific feature, product, or service that is not yet widely available to the general
user base. This phase allows for in-depth testing, feedback gathering, and fine-tuning of the component
before it reaches a broader audience.`}</p>
    <h3 {...{
      "id": "automated-public-key-infrastructure"
    }}>{`Automated Public-Key Infrastructure`}</h3>
    <p>{`Automated Private Public Key Infrastructure (PKI) is a foundational building block within Ocelot,
designed to simplify and enhance the management of digital certificates, keys, and encryption across your
microservices ecosystem. This module automates the process of creating, distributing, and revoking digital
certificates, enabling secure communication, identity verification, and data protection within your
microservices architecture.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Practical Example:`}</p>
      <p parentName="blockquote">{`Consider a scenario where your organization operates a distributed healthcare platform that relies on secure
communication between various microservices:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Certificate Lifecycle Management`}</strong>{`: With Automated Private PKI, Ocelot streamlines the issuance and renewal of
digital certificates required for secure communication. For example, a medical records service can automatically
request and obtain certificates to ensure encrypted data transfer.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Automated Key Distribution`}</strong>{`: Ocelot's PKI module automates the secure distribution of public keys to the relevant
microservices. When a new service is added to the ecosystem, it automatically receives the necessary keys for secure
communication without manual intervention.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Certificate Revocation`}</strong>{`: In the event of a security breach or when a microservice is decommissioned,
Automated Private PKI allows for the automated revocation of certificates, preventing unauthorized access
and maintaining security.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Encryption as a Default`}</strong>{`: The PKI module ensures that encryption becomes the default mode of communication
between microservices. It enforces strong security practices by default, protecting sensitive data from
unauthorized access during transit.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Integration with Identity Management`}</strong>{`: Automated Private PKI seamlessly integrates with identity and access
management systems, ensuring that only authorized services and users receive the required certificates and
access permissions.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Audit and Compliance`}</strong>{`: Ocelot maintains detailed logs and audit trails of certificate issuance,
distribution, and revocation, facilitating compliance with security and regulatory standards.`}</p>
        </li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      